<template>
	<div class="mx-4">
		<div class="overflow-y" style="max-height: calc(100vh - 250px)">
			<v-layout class="border-bottom-light-grey min-height-57px">
				<v-flex class="font-level-3-bold my-auto d-flex">
					<div>
						<!-- <span class="detail-svg-icon mr-2"> -->
						<!--begin::Svg Icon-->
						<inline-svg
							style="width: 20px; height: 20px"
							fill="#006fc8"
							:src="$assetURL('media/custom-svg/profrom2.svg')"
						/>
						<!--end ::Svg Icon-->
						<span class="ml-4">Proforma</span>
					</div>
					<div class="d-flex ml-8">
						<div>
							<v-chip class="py-2 ml-2" color="orange" label outlined
								>Total Proforma: {{ proformaListing.length }}</v-chip
							>
						</div>

						<div class="mx-8">
							<v-chip class="py-2 ml-2" color="blue" label outlined
								>Total Draft: {{ totalDraftProforma }}</v-chip
							>
						</div>

						<!-- <div class="ml-8">
							<v-chip class="py-2 ml-2" color="green" label outlined>Total Pending: 5</v-chip>
						</div> -->
						<div>
							<v-chip class="py-2 ml-2" color="green" label outlined
								>Total Accepted: {{ totalAcceptedProforma }}</v-chip
							>
						</div>
						<div class="ml-8">
							<v-chip class="py-2 ml-2" color="cyan lighten-1" label outlined>Total Invoice: 10</v-chip>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<v-simple-table
				v-if="!pageLoading"
				class="bt-table listing-table table-head-sticky"
				style="width: 100%"
			>
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Proforma NO</th>
						<th>Title</th>
						<th>Proforma Amount</th>
						<th width="150px">Invoice Amount</th>
						<!-- <th width="150px">Proforma No</th> -->
						<!-- <th width="150px">Amount</th> -->
						<!-- <th>Status</th> -->
						<th>Actions</th>
					</tr>
				</thead>
				<tbody v-if="proformaListing.length">
					<tr v-for="(row, index) in proformaListing" :key="index + 1">
						<td width="10px">{{ index + 1 }}</td>
						<td width="180px" class="text-capitalize truncate-text">
							<Chip tooltip tooltip-text="Proforma No" :text="row.barcode" color="cyan darken-4"> </Chip>
							<div class="mt-1">
								<v-chip small="small" color="cyan" label outlined>
									{{ row.status_value }}
								</v-chip>
							</div>
						</td>
						<td width="280px" class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="title" label="Tittle"></ShowValue>
						</td>

						<td width="250px">
							<div>
								<span class="fw-500">Raised : </span>
								<ShowPrice :object="row" object-key="amount" label="Amount Raised"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Accepted : </span>
								<ShowPrice :object="row" object-key="approved_amount" label="Collected Raised"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								<ShowPrice :object="row" object-key="balance" label="Balance"></ShowPrice>
							</div>
						</td>
						<td width="250px">
							<!-- <Chip tooltip tooltip-text="Proforma Invoice" :text="row.proforma_no" color="cyan darken-4"> -->
							<!-- <ShowPrice :object="row" object-key="invoice_amount" label="Invoice Amount"> </ShowPrice> -->
							<div>
								<span class="fw-500">Raised : </span>
								<ShowPrice :object="row" object-key="raised_amount" label="Amount Raised"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Collected : </span>
								<ShowPrice :object="row" object-key="collected_amount" label="Collected Raised"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Pending : </span>
								<ShowPrice :object="row" object-key="pending_amount" label="Pending Raised"></ShowPrice>
							</div>
						</td>
						<!-- <td>
							<ShowPrice :object="row" object-key="amount" label="Amount"> </ShowPrice>
						</td> -->
						<td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-if="row.status_value !== 'accepted'"
										v-bind="attrs"
										v-on="on"
										class="mr-2"
										color="success lighten-1"
										fab
										x-small
										@click="openApproveDialog(row)"
									>
										<v-icon color="white"> mdi-check</v-icon>
									</v-btn>
								</template>
								<span>Approve Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											:disabled="row.status_value !== 'accepted'"
											@click="goToProforma(row)"
											class="mr-2"
											color="blue darken-4"
											fab
											x-small
										>
											<v-icon color="white">mdi-pencil</v-icon></v-btn
										>
									</span>
								</template>
								<span>Update Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											v-if="row.status_value !== 'rejected'"
											@click="rejectProforma(row)"
											class="mr-2"
											color="orange"
											fab
											x-small
										>
											<v-icon color="white">mdi-close</v-icon></v-btn
										>
									</span>
								</template>
								<span>Reject Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn @click="deleteProforma(row)" class="mr-2" color="red lighten - 1" fab x-small>
											<v-icon color="white"> mdi-trash-can-outline</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Delete Proforma</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn @click="createInvoiceDialog(row)" class="mx-2" color="cyan	lighten-1" fab x-small>
											<!-- <v-icon color="white"> mdi-trash-can-outline</v-icon> -->
											<inline-svg
												fill="white"
												:src="$assetURL('media/custom-svg/receipt_long.svg')"
												height="18px"
												width="18px"
											/>
										</v-btn>
									</span>
								</template>
								<span>Generate Invoice</span>
							</v-tooltip>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no Proforma at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
			<div v-else class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</div>
		<DeleteTemplate
			type="Proforma"
			v-on:success="deleteProformaConfirm"
			v-if="deleteDialog"
			v-on:close="(deleteDialog = false), (uuid = null), (proformaDetail = null)"
			:delete-dialog="deleteDialog"
			:deleteText="proformaDetail?.uuid"
			:deleteByUrl="false"
		>
		</DeleteTemplate>
		<ApproveConfirmation
			v-if="approveDialog"
			type="Proforma"
			:updateStatus="false"
			v-on:close="approveDialog = false"
			:approve-dialog="approveDialog"
			status="mark_as_approved"
			uuid="#494dd3cd-f6ff-406e-a71a-75df422dde1"
			approve-type="quotation"
			@success="approveMilestoneProforma('accepted', null)"
		>
		</ApproveConfirmation>
		<Dialog :dialog="rejectDialog" :dialog-width="600">
			<template v-slot:title> Reject Proforma</template>
			<template v-slot:body>
				<v-form ref="rejectForm" v-model.trim="formValid" lazy-validation>
					<v-row class="delete-dialog">
						<!-- {{ rejectRemark }} -->
						<v-col md="12" class="py-0 red--text"> Are you sure to reject Proforma </v-col>
						<v-col md="12" class="py-0">
							<TextAreaInput
								hide-details
								id="reject_remark"
								placeholder="Enter Remark"
								:rules="[vrules.required(rejectRemark, 'Remark')]"
								:class="{
									required: !rejectRemark,
								}"
								v-model="rejectRemark"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn depressed tile v-on:click="rejectDialog = false"> No, Close </v-btn>
				<slot name="extra-btn"></slot>
				<v-btn class="white--text" depressed color="red lighten-1" tile @click="confirmReject">
					Yes! Reject
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import ShowPrice from "@/view/components/ShowPrice";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ApproveConfirmation from "@/view/components/ApproveConfirmation";
import TextAreaInput from "@/view/components/TextAreaInput";
// import Status from "@/view/components/Status";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
	GET_PROFORMA_BY_PROJECT_MILESTONE,
	UPDATE_MILESTONE_PROFORMA_STATUS,
	DELETE_PROFORMA,
} from "@/core/services/store/proforma.module.js";
// import DetailMilestone from "../module/projects/Milestone/Detail-Milestone.vue";
export default {
	name: "milestone-proforma",
	title: "Milestone proforma",
	// mixins: [ListingMixin],
	data() {
		return {
			pageLoading: false,
			proformaListing: [],
			proformaDetail: null,
			deleteDialog: false,
			approveDialog: false,
			rejectRemark: null,
			rejectDialog: false,
			formValid: null,
		};
	},
	components: {
		ShowPrice,
		Chip,
		ShowValue,
		DeleteTemplate,
		ApproveConfirmation,
		TextAreaInput,
		Dialog,
		// Status
	},
	methods: {
		openApproveDialog(proforma) {
			this.proformaDetail = proforma;
			this.approveDialog = true;
		},
		goToProforma(proforma) {
			this.$router.push({
				name: "proforma-create",
				params: { uuid: this.$route.params.uuid },
				query: {
					mileUUID: this.$route.query.mileUUID,
					t: new Date().getTime(),
					proformaUUID: proforma.uuid /* lineitems: true  */,
				},
			});
		},
		createInvoiceDialog() {
			this.$router.push({
				name: "create-invoice",
				params: { uuid: this.$route.params.uuid },
				query: { t: new Date().getTime() },
			});
		},
		async initProforma() {
			this.pageLoading = true;
			try {
				const data = await this.$store.dispatch(
					GET_PROFORMA_BY_PROJECT_MILESTONE,
					this.$route.query.mileUUID
				);
				this.proformaListing = data.proformas;
				console.log("this.proformaListing", this.proformaListing);
				this.pageLoading = false;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.pageLoading = false;
			} finally {
				this.pageLoading = false;
			}
		},
		async approveMilestoneProforma(action, remark) {
			const _this = this;
			let params = null;
			if (!remark) {
				params = {
					action: action,
					selected: [this.proformaDetail.uuid],
				};
			} else {
				params = {
					action: action,
					selected: [this.proformaDetail.uuid],
					remark: remark,
				};
			}

			try {
				this.pageLoading = true;
				await _this.$store.dispatch(UPDATE_MILESTONE_PROFORMA_STATUS, params);
				_this.proformaDetail = null;
				await this.initProforma();
				_this.approveDialog = false;
				this.rejectDialog = false;
				if (remark) {
					_this.$store.commit(SET_MESSAGE, [{ model: true, message: "Proforma has been rejected!" }]);
				} else {
					_this.$store.commit(SET_MESSAGE, [{ model: true, message: "Proforma has been approve!" }]);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async rejectProforma(proforma) {
			this.proformaDetail = proforma;
			this.rejectDialog = true;
		},
		async confirmReject() {
			await this.approveMilestoneProforma("rejected", this.rejectRemark);
		},
		async deleteProforma(proforma) {
			this.proformaDetail = proforma;
			this.deleteDialog = true;
		},
		async deleteProformaConfirm() {
			const param = {
				performas: [this.proformaDetail.uuid],
			};
			const _this = this;
			try {
				this.pageLoading = true;
				await _this.$store.dispatch(DELETE_PROFORMA, param);
				_this.proformaDetail = null;
				_this.approveDialog = false;
				this.deleteDialog = false;
				await this.initProforma();
				_this.$store.commit(SET_MESSAGE, [{ model: true, message: "Proforma has been deleted." }]);
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	computed: {
		totalDraftProforma() {
			let total = 0;
			this.proformaListing.forEach((item) => {
				if (item.status_value === "draft") {
					total = total + 1;
				}
			});
			return total;
		},
		totalAcceptedProforma() {
			let total = 0;
			this.proformaListing.forEach((item) => {
				if (item.status_value === "accepted") {
					total = total + 1;
				}
			});
			return total;
		},
	},
	async mounted() {
		await this.initProforma();
	},
};
</script>
