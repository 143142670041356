<template>
	<div class="mx-4">
		<div class="overflow-y" style="max-height: calc(100vh - 250px)">
			<v-layout class="border-bottom-light-grey min-height-57px">
				<v-flex class="font-level-3-bold my-auto d-flex justify-space-between">
					<div>
						<!--begin::Svg Icon-->
						<inline-svg
							style="width: 20px; height: 20px"
							fill="#006fc8"
							:src="$assetURL('media/custom-svg/invoice_1.svg')"
						/>
						<!--end ::Svg Icon-->
						<span class="ml-4">Invoice</span>
					</div>
					<div>
						<v-btn
							@click="openInvoiceDialog"
							color="blue darken-4 mr-4 white--text"
							class="ml-4"
							tile
							depressed
						>
							<v-icon>mdi-plus</v-icon>Create</v-btn
						>
					</div>
				</v-flex>
			</v-layout>
			<v-simple-table class="bt-table listing-table table-head-sticky" style="width: 100%">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Invoice No</th>
						<th>Title</th>
						<!-- <th>Proforma No</th> -->
						<th>Amount</th>
						<th width="150px">Invoice Date</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody v-if="invoiceListing.length">
					<tr v-for="(row, index) in invoiceListing" :key="index + 1">
						<td width="10px">{{ index + 1 }}</td>

						<td width="120px">
							<Chip tooltip tooltip-text="Invoice No" :text="row.invoice_no" color="cyan darken-4"> </Chip>
							<div>
								<Chip
									class="mt-1"
									tooltip
									tooltip-text="Proforma No"
									:text="row.proforma_no"
									color="cyan darken-4"
								>
								</Chip>
							</div>
						</td>
						<td width="300px" class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="title" label="Tittle"></ShowValue>
						</td>

						<!-- <td width="120px"> -->

						<!-- </td> -->
						<td width="250px">
							<div>
								<span class="fw-500">Invoice Amount: </span>
								<ShowPrice :object="row" object-key="invoice_amount" label="Invoice amount"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Paid : </span>
								<ShowPrice :object="row" object-key="paid_amount" label="Paid"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								{{ formatMoney(row.invoice_amount - row.paid_amount) }}
							</div>
						</td>
						<td>
							<ShowValue :object="row" object-key="date" label="Date"></ShowValue>
						</td>
						<td>
							<Chip
								tooltip
								tooltip-text="Status"
								:text="row.status_value"
								:color="
									row.status_value === 'unpaid'
										? 'red'
										: row.status_value === 'paid'
										? 'green'
										: 'blue darken-4'
								"
							>
							</Chip>
							<v-btn
								@click="createInvoicePayment"
								v-if="row.status_value !== 'paid'"
								color="green darken-4 white--text"
								class="ml-4"
								depressed
								small
							>
								Pay Now</v-btn
							>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no Proforma at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import Chip from "@/view/components/Chip";
export default {
	name: "milestone-detail",
	title: "Milestone Detail",
	// mixins: [ListingMixin],
	data() {
		return {
			invoiceListing: [
				{
					title: "invoice 1",
					amount: "450",
					invoice_no: "INV0002",
					proforma_no: "PF0001",
					date: "21/03/2023",
					invoice_amount: 450,
					paid_amount: 0,
					status_value: "unpaid",
				},
				{
					title: "invoice 2",
					amount: "450",
					invoice_no: "INV0004",
					proforma_no: "PF0002",
					date: "01/03/2023",
					invoice_amount: 300,
					paid_amount: 300,
					status_value: "paid",
				},
				{
					title: "invoice 3",
					amount: "450",
					invoice_no: "INV0004",
					proforma_no: "PF0003",
					date: "4/03/2023",
					invoice_amount: 250,
					paid_amount: 200,
					status_value: "partially paid",
				},
			],
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		Chip,
		ShowValue,
		ShowPrice,
	},
	methods: {
		openInvoiceDialog() {
			this.$router.push({
				name: "create-invoice",
				params: { uuid: this.project.uuid },
				query: { t: new Date().getTime() },
			});
		},
		createInvoicePayment() {
			this.$router.push({
				name: "create-payment",
				params: { uuid: this.$route.params.uuid },
				query: { id: 3, t: new Date().getTime() },
			});
		},
	},
};
</script>
