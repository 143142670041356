<template>
	<div>
		<div class="mx-4">
			<div class="overflow-y" style="max-height: calc(100vh - 250px)">
				<v-layout class="border-bottom-light-grey min-height-57px">
					<v-flex class="font-level-3-bold my-auto d-flex justify-space-between">
						<!-- <span class="detail-svg-icon mr-2"> -->
						<!--begin::Svg Icon-->
						<div>
							<inline-svg
								style="width: 20px; height: 20px"
								fill="#006fc8"
								:src="$assetURL('media/custom-svg/payment2.svg')"
							/>
							<!--end ::Svg Icon-->
							<span class="ml-4">Payment</span>
						</div>
						<div>
							<v-btn
								@click="createPayment"
								color="blue darken-4 mr-4 white--text"
								class="ml-4"
								tile
								depressed
							>
								<v-icon>mdi-plus</v-icon>Create</v-btn
							>
						</div>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table table-head-sticky">
					<thead>
						<tr>
							<th width="10px">#</th>
							<th>Invoice NO</th>
							<th>Invoice Title</th>
							<th>Date</th>
							<th>Invoice Amount</th>
							<!-- <th>Payment Date</th> -->
							<!-- <th>Proforma No</th> -->
							<th>Attachment</th>
							<th>Reference</th>
							<!-- <th>Status</th> -->
							<th>Actions</th>
						</tr>
					</thead>
					<tbody v-if="paymentList.length">
						<tr class="cursor-pointer" v-for="(row, index) in paymentList" :key="index + 1">
							<td width="10px">{{ index + 1 }}</td>
							<td>
								<Chip tooltip tooltip-text="Invoice No" :text="row.invoice_barcode" color="cyan"></Chip>
								<div>
									<Chip
										class="mt-1"
										tooltip
										tooltip-text="Invoice No"
										:text="row.proforma_barcode"
										color="cyan"
									></Chip>
								</div>
							</td>
							<td>
								<ShowValue :object="row" object-key="invoice_title" label="Invoice Tittle"> </ShowValue>
							</td>
							<td>
								<div>
									<span class="fw-500">Invoice Date : </span>
									<ShowValue :object="row" object-key="invoice_date" label="Invoice Date"></ShowValue>
								</div>
								<div>
									<span class="fw-500">Payment Date: </span>
									<ShowValue :object="row" object-key="payment_date" label="Date"> </ShowValue>
								</div>
							</td>
							<td>
								<!-- <ShowPrice :object="row" object-key="invoice_amount" label="invoice_amount"> </ShowPrice> -->
								<div>
									<span class="fw-500">Invoice Amount: </span>
									<ShowPrice :object="row" object-key="invoice_amount" label="Invoice Amount"> </ShowPrice>
								</div>
								<div>
									<span class="fw-500">Amount Received: </span>
									<ShowPrice :object="row" object-key="amount_received" label="Amount Received"> </ShowPrice>
								</div>
								<div>
									<span class="fw-500">Balance: </span>
									{{ formatMoney(row.invoice_amount - row.amount_received) }}
								</div>
							</td>
							<!-- <td>
								<ShowValue :object="row" object-key="payment_date" label="Date"> </ShowValue>
							</td> -->
							<!-- <td>
								<ShowPrice :object="row" object-key="invoice_amount" label="Amount"> </ShowPrice>
							</td> -->
							<!-- <td>
								<Chip tooltip tooltip-text="Invoice No" :text="row.invoice_barcode" color="cyan"></Chip>
							</td> -->
							<td style="width: 100px" class="p-2 border-top-light-grey">
								<div>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-on="on" v-bind="attrs">
												<v-btn
													fab
													icon
													small
													:loading="row.downloading"
													@click.stop.prevent="
														downloadAttachment(
															'https://www.avaza.com/wp-content/uploads/2016/08/Invoice_Attachment_Full_View.webp'
														)
													"
												>
													<v-icon color="" class="cursor-pointer">mdi-download-circle</v-icon>
												</v-btn>
											</span>
										</template>
										<span>Download Attachement</span>
									</v-tooltip>
									<span class="ml-1">Item2.webp</span>
								</div>
								<!-- <em v-else class="text-muted">no attachement</em> -->
							</td>
							<td>
								<ShowValue :object="row" object-key="reference" label="Reference"> </ShowValue>
							</td>
							<!-- <td>
								<Chip tooltip tooltip-text="Status #" :text="row.status_value" :color="
									row.status_value === 'paid'
										? 'green'
										: row.status_value === 'partial-paid'
											? 'blue'
											: 'red'
								">
								</Chip>
							</td> -->
							<td>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											@click.prevent.stop="openDeleteDialog(row)"
											color="red lighten-1"
											v-bind="attrs"
											class="mx-2"
											v-on="on"
											dark
											fab
											x-small
										>
											<v-icon small dark> mdi-trash-can-outline</v-icon>
										</v-btn>
									</template>
									<span>Delete Payment</span>
								</v-tooltip>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no payment at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</div>
		</div>
		<DeleteTemplate
			type="Payment"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			deleteText="494dd3cd-f6ff-406e-a71a-75df422dde1e"
			:deleteByUrl="false"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import Chip from "@/view/components/Chip";
export default {
	name: "milestone-detail",
	title: "Milestone Detail",
	// mixins: [ListingMixin],
	data() {
		return {
			paymentList: [
				{
					invoice_title: "title 1",
					invoice_barcode: "INV0004",
					proforma_barcode: "PR0004",
					invoice_date: "10/03/2023",
					invoice_amount: "4500",
					payment_date: "10/04/2023",
					amount_received: "1200",
					status_value: "unpaid",
					reference: "wwf4ds4dsd5",
				},
				{
					invoice_title: "title 2",
					invoice_barcode: "INV0005",
					proforma_barcode: "PR0002",
					invoice_date: "10/03/2023",
					payment_date: "10/04/2023",
					invoice_amount: "8000",
					status_value: "paid",
					amount_received: "4200",
					reference: "kwftssd",
				},
			],
			deleteDialog: false,
		};
	},
	components: {
		ShowValue,
		Chip,
		ShowPrice,
		DeleteTemplate,
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openDeleteDialog() {
			this.deleteDialog = true;
		},
		downloadAttachment(document_url) {
			window.open(document_url, "_blank");
		},
		createPayment() {
			this.$router.push({
				name: "create-payment",
				params: { uuid: this.project.uuid },
				query: { t: new Date().getTime() },
			});
		},
	},
};
</script>
